<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!formulaInformation">
      Agregar formula
    </h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        class="special-margin"
        @click="pushFormulaArticle()"
        >Agregar materia prima extra</b-button
      >

      <b-button
        type="is-primary"
        class="special-margin"
        @click="pushResultArticle()"
        >Agregar resultado extra</b-button
      >
    </div>

    <div v-if="!formulaInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span> Las materias primas
      con campos vacios o en 0 seran
      <span class="global-form-hint-color"> ignorados. </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': formulaRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción no es valida': formulaRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Marca 1"
          expanded
          icon="numeric"
          v-model="newFormula.DESCRIPCION"
          required
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Cantidad a transformar</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad a transformar (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Marca 1"
          expanded
          icon="numeric"
          type="number"
          step="any"
          v-model="newFormula.CANTIDAD_TRANSFORMAR"
          required
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Resultado</h3>

    <div v-for="(item, index) in resultArticles" :key="`resultado-${index}`">
      <b-field grouped class="special-margin">
        <b-field
          label="Artículo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
        >
          <b-autocomplete
            :data="articles"
            field="DESCRIPCION"
            v-model="item.ART"
            open-on-focus
            placeholder="Ejemplo: Pala"
            expanded
            icon="screwdriver"
            clearable
            @select="
              (option) => (item.CLAVE_ART = option ? option._id : undefined)
            "
            :loading="isFetching"
            @typing="searchArticles"
          >
            <template #empty>No se encontraron artículos</template>
          </b-autocomplete>
        </b-field>

        <b-field label="Cantidad (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Marca 1"
            expanded
            icon="numeric"
            type="number"
            step="any"
            v-model="item.CANTIDAD"
            required
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div v-for="(item, index) in formulaArticles" :key="`materia-${index}`">
      <h3 class="form-division">Materia prima {{ index + 1 }}</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Artículo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
        >
          <b-autocomplete
            :data="articles"
            field="DESCRIPCION"
            v-model="item.ART"
            open-on-focus
            placeholder="Ejemplo: Pala"
            expanded
            icon="screwdriver"
            clearable
            @select="
              (option) => (item.CLAVE_ART = option ? option._id : undefined)
            "
            :loading="isFetching"
            @typing="searchArticles"
          >
            <template #empty>No se encontraron artículos</template>
          </b-autocomplete>
        </b-field>

        <b-field label="Cantidad (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Marca 1"
            expanded
            icon="numeric"
            type="number"
            step="any"
            v-model="item.CANTIDAD"
            required
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls" v-if="formulaInformation">
      <b-button type="is-success" @click="saveFormula(1)"
        >Actualizar formula</b-button
      >
      <b-button
        type="is-danger"
        @click="startDeleteFormula(formulaInformation)"
        class="cancel-button"
        >Borrar formula</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!formulaInformation">
      <b-button type="is-success" @click="saveFormula(0)"
        >Añadir formula</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { validateFields, checkValidationErrors } from "@/utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import {
  fillObjectForm,
  openConfirmationModalDanger,
} from "@/utils/component-fns";

// @ is an alias to /src
export default {
  name: "AddFormulaModal",
  props: ["formulaInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newFormula: {
        CANTIDAD_TRANSFORMAR: 1,
        DESCRIPCION: "",
      },
      resultArticles: [],
      formulaArticles: [],
      formulaRequiredErrors: {
        CANTIDAD_TRANSFORMAR: false,
        DESCRIPCION: false,
      },
      currentFormulaArticle: 0,
      currentResultArticle: 0,
      isFetching: false,
    };
  },
  mounted() {
    // Fill the formula information in form fields
    if (this.formulaInformation) {
      // Fill the form with received information
      fillObjectForm(this.formulaInformation, this.newFormula);
      this.newFormula._id = this.formulaInformation._id;

      for (const [
        singleFormulaItemIndex,
        singleFormulaItem,
      ] of this.formulaInformation.RESULTADOS.entries()) {
        this.pushResultArticle();

        this.resultArticles[singleFormulaItemIndex].CLAVE_ART =
          singleFormulaItem.CLAVE_ART._id;
        this.resultArticles[singleFormulaItemIndex].CANTIDAD =
          singleFormulaItem.CANTIDAD;
        this.resultArticles[singleFormulaItemIndex].ART =
          singleFormulaItem.CLAVE_ART.DESCRIPCION;
      }

      for (const [
        singleFormulaItemIndex,
        singleFormulaItem,
      ] of this.formulaInformation.MATERIALES.entries()) {
        this.pushFormulaArticle();

        this.formulaArticles[singleFormulaItemIndex].CLAVE_ART =
          singleFormulaItem.CLAVE_ART._id;
        this.formulaArticles[singleFormulaItemIndex].CANTIDAD =
          singleFormulaItem.CANTIDAD;
        this.formulaArticles[singleFormulaItemIndex].ART =
          singleFormulaItem.CLAVE_ART.DESCRIPCION;
      }
    } else {
      this.pushResultArticle();
      this.pushFormulaArticle();
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    pushFormulaArticle() {
      let tempFormulaArticle = {
        CLAVE_ART: undefined,
        ART: "",
        CANTIDAD: 0,
      };
      this.formulaArticles.push(tempFormulaArticle);
      this.currentFormulaArticle++;
    },
    pushResultArticle() {
      let tempResultArticle = {
        CLAVE_ART: undefined,
        ART: "",
        CANTIDAD: 0,
      };
      this.resultArticles.push(tempResultArticle);
      this.currentResultArticle++;
    },
    /**
     * @desc saves or updates a formula in the database depending on type
     */
    async saveFormula(type) {
      try {
        this.removeNotValid();
        this.formulaRequiredErrors = validateFields(
          this.newFormula,
          this.formulaRequiredErrors
        );
        if (checkValidationErrors(this.formulaRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVEFORMULA" : "EDITFORMULA",
            {
              ...this.newFormula,
              MATERIALES: this.formulaArticles,
              RESULTADOS: this.resultArticles,
            }
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la formula",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la formula en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la formula en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la formula en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    removeNotValid() {
      this.formulaArticles = this.formulaArticles.filter((singleArticle) => {
        if (!singleArticle.CLAVE_ART) return false;
        if (!singleArticle.CANTIDAD) return false;
        return true;
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    startDeleteFormula(formulaInformation) {
      this.startCancelEvent(
        "danger",
        () => {
          this.deleteFormula(formulaInformation);
        },
        "Borras formula"
      );
    },
    async deleteFormula(formulaInformation) {
      try {
        let response = await this.$store.dispatch("DELETEFORMULA", {
          formulaId: formulaInformation._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borró exitosamente la formula",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al borrar la formula en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al borrar la formula en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    searchArticles: debounce(async function (search) {
      try {
        if (!search.length) {
          this.data = [];
          return;
        }

        this.isFetching = true;

        await this.$store.dispatch("SEARCHARTICLES", {
          skip: 0,
          limit: 50,
          search: search,
        });

        this.isFetching = false;
      } catch (e) {
        this.isFetching = false;
      }
    }, 300),
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
