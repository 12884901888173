<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Transformación de artículos </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Formulas" icon="format-list-bulleted-type">
        <FormulaList />
      </b-tab-item>
      <b-tab-item label="Transformaciónes" icon="arrow-collapse-horizontal">
        <ArticleTransformationsList />
      </b-tab-item>
      <b-tab-item label="Artículos transformados" icon="arrow-collapse-right">
        <ArticleTransformationsDetailsList />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import FormulaList from "../components/FormulaList";
import ArticleTransformationsList from "../components/ArticleTransformationsList";
import ArticleTransformationsDetailsList from "../components/ArticleTransformationsDetailsList";

export default {
  name: "ArticlesTransformationComponents",
  components: {
    FormulaList,
    ArticleTransformationsList,
    ArticleTransformationsDetailsList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "transformation");
  },
  async created() {
    let allPromises = [];

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
