<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls"> </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de formulas: <b>{{ formulas.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="formulas"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="formulas"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="DESCRIPCION"
        @click="(row) => selectFormula(row)"
      >
        <template #empty>
          <div class="has-text-centered">No hay formulas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p>{{ props.row[column.field] }}</p>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { EventBus } from "@/event-bus";

export default {
  name: "FormulaList",
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD_TRANSFORMAR",
          label: "Cantidad",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async selectFormula(formula) {
      EventBus.$emit("searchFormulaSelected", {
        formula,
      });

      this.closeModal();
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    formulas() {
      return this.$store.getters.FORMULAS.map((singleFormula) => {
        const formulaWithProps = { ...singleFormula };

        return formulaWithProps;
      });
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.activeDimensionsWeight = true;
        articleWithProps.disableIva = true;
        articleWithProps.activeFamily = true;
        articleWithProps.activeSFamily = true;
        articleWithProps.activeSSFamily = true;
        articleWithProps.activeSSSFamily = true;
        articleWithProps.shippingActive = true;
        articleWithProps.tarrifActive = true;
        articleWithProps.retentionActive = true;
        articleWithProps.totalQuantity = 0;
        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }
        if (singleArticle.FE_ULTCMP) {
          articleWithProps.lastBuyFormat = moment(
            singleArticle.FE_ULTCMP
          ).format("DD-MM-YYYY HH:mm");
        }
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }
        return articleWithProps;
      });
    },
  },
  async created() {
    moment.locale("es");

    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETFORMULAS"));

    await Promise.all(allPromises);
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
