<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title" v-if="!transformationInformation">
      Agregar transformación
    </h1>

    <div class="control add-global-controls" v-if="!transformationInformation">
      <b-button
        type="is-primary"
        class="special-margin"
        @click="pushTransformationArticle()"
        >Agregar materia prima extra</b-button
      >
      <b-button
        type="is-primary"
        class="special-margin"
        @click="pushResultArticle()"
        >Agregar resultado extra</b-button
      >
      <b-button
        type="is-primary"
        class="special-margin"
        @click="openAddArticleTransformation()"
        >Usar formula</b-button
      >
    </div>

    <div v-if="!transformationInformation" class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span> Las materias primas
      con campos vacios o en 0 seran
      <span class="global-form-hint-color"> ignorados. </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': transformationRequiredErrors.DESCRIPCION }"
        :message="{
          'La descripción no es valida':
            transformationRequiredErrors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Marca 1"
          expanded
          icon="numeric"
          v-model="newTransformation.DESCRIPCION"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Almacen (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        required
        :type="{ 'is-danger': transformationRequiredErrors.CLAVE_ALM }"
        :message="{
          'El almacen no es valido': transformationRequiredErrors.CLAVE_ALM,
        }"
      >
        <b-autocomplete
          autocomplete
          :data="autoCompleteWarehouse(warehouseName)"
          field="NOMBRE_ALM"
          v-model="warehouseName"
          open-on-focus
          placeholder="Ejemplo: Pala"
          expanded
          icon="warehouse"
          clearable
          @select="
            (option) =>
              (newTransformation.CLAVE_ALM = option ? option._id : undefined)
          "
          check-infinite-scroll
        >
          <template #empty>No se encontraron almacenes</template>
        </b-autocomplete>
      </b-field>
    </b-field>

    <h3 class="form-division">Cantidad a transformar</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad a transformar (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Marca 1"
          expanded
          icon="numeric"
          type="number"
          step="any"
          v-model="newTransformation.CANTIDAD_TRANSFORMAR"
          required
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Resultado</h3>

    <div v-for="(item, index) in resultArticles" :key="`resultado-${index}`">
      <b-field grouped class="special-margin">
        <b-field
          label="Artículo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
        >
          <b-autocomplete
            :data="articles"
            field="DESCRIPCION"
            v-model="item.ART"
            open-on-focus
            placeholder="Ejemplo: Pala"
            expanded
            icon="screwdriver"
            clearable
            @select="
              (option) => (item.CLAVE_ART = option ? option._id : undefined)
            "
            :loading="isFetching"
            @typing="searchArticles"
          >
            <template #empty>No se encontraron artículos</template>
          </b-autocomplete>
        </b-field>

        <b-field label="Cantidad (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Marca 1"
            expanded
            icon="numeric"
            type="number"
            step="any"
            v-model="item.CANTIDAD"
            required
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div
      v-for="(item, index) in transformationArticles"
      :key="`materia-${index}`"
    >
      <h3 class="form-division">Materia prima {{ index + 1 }}</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Artículo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
        >
          <b-autocomplete
            :data="articles"
            field="DESCRIPCION"
            v-model="item.ART"
            open-on-focus
            placeholder="Ejemplo: Pala"
            expanded
            icon="screwdriver"
            clearable
            @select="
              (option) => (item.CLAVE_ART = option ? option._id : undefined)
            "
            :loading="isFetching"
            @typing="searchArticles"
          >
            <template #empty>No se encontraron artículos</template>
          </b-autocomplete>
        </b-field>

        <b-field label="Cantidad (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Marca 1"
            expanded
            icon="numeric"
            type="number"
            step="any"
            v-model="item.CANTIDAD"
            required
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div
      class="add-global-controls"
      v-if="
        transformationInformation && transformationInformation.STATUS !== 'CA'
      "
    >
      <b-button
        type="is-danger"
        @click="startCancelTransformation(transformationInformation)"
        >Cancelar transformación</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!transformationInformation">
      <b-button type="is-success" @click="saveTransformation()"
        >Transformar material</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { validateFields, checkValidationErrors } from "@/utils/fns";
import { EventBus } from "@/event-bus";
import SelectFormula from "./SelectFormula.vue";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
import {
  fillObjectForm,
  openConfirmationModalDanger,
} from "@/utils/component-fns";

// @ is an alias to /src
export default {
  name: "AddArticleTransformation",
  props: ["transformationInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newTransformation: {
        DESCRIPCION: "",
        CANTIDAD_TRANSFORMAR: 1,
        CLAVE_ART: undefined,
        CLAVE_ALM: undefined,
        CANTIDAD: 0,
      },
      resultArticles: [],
      transformationArticles: [],
      transformationRequiredErrors: {
        CANTIDAD_TRANSFORMAR: false,
        DESCRIPCION: false,
        CLAVE_ALM: false,
      },
      warehouseName: "",
      currentResultArticle: 0,
      currentTransformationArticle: 0,
      isFetching: false,
    };
  },
  mounted() {
    // Fill the transformation information in form fields
    if (this.transformationInformation) {
      // Fill the form with received information
      fillObjectForm(this.transformationInformation, this.newTransformation);
      this.newTransformation._id = this.transformationInformation._id;

      this.newTransformation.CLAVE_ALM =
        this.transformationInformation.CLAVE_ALM._id;
      this.warehouseName = this.transformationInformation.CLAVE_ALM.NOMBRE_ALM;

      this.transformationArticles = [];
      this.resultArticles = [];

      for (const [
        singleTransformationItemIndex,
        singleTransformationItem,
      ] of this.transformationInformation.RESULTADOS.entries()) {
        this.pushResultArticle();

        this.resultArticles[singleTransformationItemIndex].CLAVE_ART =
          singleTransformationItem.CLAVE_ART._id;
        this.resultArticles[singleTransformationItemIndex].CANTIDAD =
          singleTransformationItem.CANTIDAD;
        this.resultArticles[singleTransformationItemIndex].ART =
          singleTransformationItem.CLAVE_ART.DESCRIPCION;
      }

      for (const [
        singleTransformationItemIndex,
        singleTransformationItem,
      ] of this.transformationInformation.MATERIALES.entries()) {
        this.pushTransformationArticle();

        this.transformationArticles[singleTransformationItemIndex].CLAVE_ART =
          singleTransformationItem.CLAVE_ART._id;
        this.transformationArticles[singleTransformationItemIndex].CANTIDAD =
          singleTransformationItem.CANTIDAD;
        this.transformationArticles[singleTransformationItemIndex].ART =
          singleTransformationItem.CLAVE_ART.DESCRIPCION;
      }
    } else {
      this.pushResultArticle();
      this.pushTransformationArticle();
    }

    EventBus.$on("searchFormulaSelected", (formulaSelected) => {
      const formulaInformation = formulaSelected.formula;

      this.newTransformation.DESCRIPCION = formulaInformation.DESCRIPCION;
      this.newTransformation.CANTIDAD = formulaInformation.CANTIDAD;
      this.newTransformation.CANTIDAD_TRANSFORMAR =
        formulaInformation.CANTIDAD_TRANSFORMAR;

      this.transformationArticles = [];
      this.resultArticles = [];

      for (const [
        singleTransformationItemIndex,
        singleTransformationItem,
      ] of formulaInformation.RESULTADOS.entries()) {
        this.pushResultArticle();

        this.resultArticles[singleTransformationItemIndex].CLAVE_ART =
          singleTransformationItem.CLAVE_ART._id;
        this.resultArticles[singleTransformationItemIndex].CANTIDAD =
          singleTransformationItem.CANTIDAD;
        this.resultArticles[singleTransformationItemIndex].ART =
          singleTransformationItem.CLAVE_ART.DESCRIPCION;
      }

      for (const [
        singleTransformationItemIndex,
        singleTransformationItem,
      ] of formulaInformation.MATERIALES.entries()) {
        this.pushTransformationArticle();

        this.transformationArticles[singleTransformationItemIndex].CLAVE_ART =
          singleTransformationItem.CLAVE_ART._id;
        this.transformationArticles[singleTransformationItemIndex].CANTIDAD =
          singleTransformationItem.CANTIDAD;
        this.transformationArticles[singleTransformationItemIndex].ART =
          singleTransformationItem.CLAVE_ART.DESCRIPCION;
      }
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    pushTransformationArticle() {
      let tempTransformationArticle = {
        CLAVE_ART: undefined,
        ART: "",
        CANTIDAD: 0,
      };
      this.transformationArticles.push(tempTransformationArticle);
      this.currentTransformationArticle++;
    },
    pushResultArticle() {
      let tempResultArticle = {
        CLAVE_ART: undefined,
        ART: "",
        CANTIDAD: 0,
      };
      this.resultArticles.push(tempResultArticle);
      this.currentResultArticle++;
    },
    /**
     * @desc saves or updates transformation in the database depending on type
     */
    async saveTransformation(type) {
      try {
        this.removeNotValid();
        this.transformationRequiredErrors = validateFields(
          this.newTransformation,
          this.transformationRequiredErrors
        );
        if (checkValidationErrors(this.transformationRequiredErrors)) {
          let response = await this.$store.dispatch(
            "SAVEARTICLETRANSFORMATION",
            {
              ...this.newTransformation,
              MATERIALES: this.transformationArticles,
              RESULTADOS: this.resultArticles,
            }
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la transformación",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la transformación en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la transformación en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la transformación en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    autoCompleteWarehouse(value) {
      if (!this.warehouses) {
        return [];
      }
      return this.warehouses.filter((singleArticle) => {
        return (
          singleArticle.CLAVE_ALMACEN.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singleArticle.NOMBRE_ALM.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    removeNotValid() {
      this.transformationArticles = this.transformationArticles.filter(
        (singleArticle) => {
          if (!singleArticle.CLAVE_ART) return false;
          if (!singleArticle.CANTIDAD) return false;
          return true;
        }
      );
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    startCancelTransformation(transformationInformation) {
      this.startCancelEvent(
        "danger",
        () => {
          this.cancelTransformation(transformationInformation);
        },
        "Cancelar transformación"
      );
    },
    async cancelTransformation(transformationInformation) {
      try {
        let response = await this.$store.dispatch(
          "CANCELARTICLETRANSFORMATION",
          {
            transformationId: transformationInformation._id,
          }
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borró exitosamente la transformación",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al borrar la transformación en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al borrar la transformación en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddArticleTransformation() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectFormula,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    searchArticles: debounce(async function (search) {
      try {
        if (!search.length) {
          this.data = [];
          return;
        }

        this.isFetching = true;

        await this.$store.dispatch("SEARCHARTICLES", {
          skip: 0,
          limit: 50,
          search: search,
        });

        this.isFetching = false;
      } catch (e) {
        this.isFetching = false;
      }
    }, 300),
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
